import NumberHelpers from "number_helpers";
import _ from "lodash";

export function formattedDollarAmount(amount, precision = 0) {
  const numberOptions = {
    significant: false,
    precision,
    labels: { million: "M", billion: "B", thousand: "K" },
    space_label: false,
  };
  const rounded = _.round(amount, 2);
  return NumberHelpers.number_to_human(rounded, numberOptions);
}

export function dollarAmount(amount, precision = 0) {
  const rounded = _.round(amount, 2);
  return NumberHelpers.number_to_currency(rounded, { precision });
}

export function currencyAmount(amount, precision = 0, roundPlaces = 2) {
  const rounded = _.round(amount, roundPlaces);
  return NumberHelpers.number_to_currency(rounded, { precision, unit: "" });
}

const currencyThreshold = 1_000_000;

export function currencyBelowThreshold(
  amount,
  threshold = currencyThreshold,
  precision = 1
) {
  if (amount < threshold) {
    return currencyAmount(amount);
  } else {
    return formattedDollarAmount(amount, precision);
  }
}
